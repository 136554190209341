import React, { useEffect } from 'react'
import './loader.css'

function Loader(props) {


    useEffect(() => {
        let circles = document.getElementsByClassName('circle');
        Array.from(circles).forEach(element => {
            element.style.height = props.height;
            element.style.width = props.height;
        });
    }, [])
    if (props.show) {
        return (<div className="loader">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
        </div>
        )
    } else {
        return (null)
    }

}

export default Loader