import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
import myVideo from "./mass-http-guide.mov";

const firebaseConfig = {
  apiKey: "AIzaSyDw-Fk68HQ5GTvu-0cmYuyObjnTlOedXGI",
  authDomain: "mass-http.firebaseapp.com",
  projectId: "mass-http",
  storageBucket: "mass-http.appspot.com",
  messagingSenderId: "978782486079",
  appId: "1:978782486079:web:9ff056c2559dfc4fa8c28a",
  measurementId: "G-TDB6HES2JP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="logo-container" id="logo">
      <img className="logo-image" src={require("./logo.png")} />
      <span id="logo-helper-text">
        The only tool you will ever need for HTTP Probe
      </span>
    </div>
    <App />
    <div className="video-section">
    <h1>How it works?</h1>
      <video style={{border: 'solid 1px', marginBottom: '85px'}} width="800" height="600" autoPlay loop muted>
        <source src={myVideo} type="video/mp4"></source>
      </video>
      </div>
    <div class="footer">
      
      <p id="copyrights">© Copyright 2023 mass-http. All rights reserved.</p>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
