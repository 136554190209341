import "./App.css";
import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Headline from "./Headline";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloudQueue from "@mui/icons-material/CloudQueue";
import ReCAPTCHA from "react-google-recaptcha";

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowErrorMessage, setIsShowErrorMessage] = useState(false);
  const [hasResultsArrived, setHasResultsArrived] = useState(false);
  const [results, setResults] = useState([]);
  const [urls, setUrls] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [captcha, setCaptcha] = useState("");
  const [expanded, setExpanded] = React.useState(false);
  const recaptchaRef = React.createRef();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setResults(JSON.parse(localStorage.getItem("res")));
  });

  function openModal(headers){
    setShowModal(true)
    console.log(headers)
    setModalContent(JSON.stringify(headers));
  }

  function closeModal(){
    setShowModal(false)
  }

  function onChange() {
    const recaptchaValue = recaptchaRef.current.getValue();
    console.log("Captcha value:", recaptchaValue);
    setCaptcha(recaptchaValue);
  }
  function handleFileChange(file) {
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      let content = reader.result;
      setUrls(content);
      console.log(content);
    };
    reader.onerro = () => {
      console.log("File reader error: ", reader.error);
    };
  }
  function firstToast() {
    toast(
      "Why dont you enter more than 1 target. We will love to find bulk results for you!",
      {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  }

  function badgeClassForStatus(status) {
    if(status == "Could not reach"){
      return "badge-danger";
    } else { 
      let statusCode = parseInt(/^(\d+)\s/.exec(status)[0]);
      if (statusCode > 499) {
        return "badge-danger";
      } else if (statusCode > 399) {
        return "badge-warning";
      } else if (statusCode > 299) {
        return "badge-info";
      } else if (statusCode > 199) {
        return "badge-success";
      } else {
        return "badge-secondary";
      }
}
    
  }

  function secondToast(count) {
    let closeTime = count + "000";
    console.log("closeTime is: ", closeTime);
    toast.success(`🦄 The estimated time for results is ${count} seconds`, {
      position: "bottom-center",
      autoClose: Number(closeTime),
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  const clicked = async () => {
    localStorage.removeItem("res");
    if (urls.length > 0) {
      setHasResultsArrived(false);
      setIsLoading(true);
      console.log(urls);
      let forSending = urls.split("\n");
      if (forSending.length === 1) firstToast();
      // secondToast(forSending.length)

      for (let i = 0; i < forSending.length; i++) {
        let httpResponse = await makeHTTPRequest(forSending[i]);

        console.log("Results received");
        setHasResultsArrived(true);

        let parsedReturnedData = JSON.parse(httpResponse.data);
        console.log(parsedReturnedData);
        if (parsedReturnedData.success == true) {
          let obj = {};
          obj = parsedReturnedData.z[0];
          obj.screenshot = parsedReturnedData.data;
          console.log("Here", obj);
          let get = localStorage.getItem("res");
          if (get != undefined) {
            get = [...JSON.parse(get), obj];
            console.log("There", get);
            localStorage.setItem("res", JSON.stringify(get));
          } else {
            let stringObj = JSON.stringify(obj);
            localStorage.setItem("res", "[" + stringObj + "]");
          }
        } else {
          alert(parsedReturnedData.msg);
        }
      }
      setIsLoading(false);
      /* let httpResponse = await makeHTTPRequest(forSending);
      console.log("Results received");
      setHasResultsArrived(true);
      setIsLoading(false);
      let parsedReturnedData = JSON.parse(httpResponse.data);
      console.log(parsedReturnedData);
      if (parsedReturnedData.success == true) {
        let ar = [];
        ar = parsedReturnedData.z;
        setResults(ar);
      } else {
        alert(parsedReturnedData.msg);
      } */
    } else {
      alert("Please enter atleast one target");
    }
  };

  return (
    <div className="App">
      <Headline />

      <div className="input-container">
        <textarea
          id="find-box"
          onChange={(e) => setUrls(e.target.value)}
          placeholder="google.com&#10;clickjacker.io&#10;www.hackerone.com&#10;uber.com&#10;www.bugcrowd.com&#10;twitter.com&#10;https://example.com&#10;http://example.com"
          rows="15"
          cols="50"
          value={urls}
        ></textarea>
        {isShowErrorMessage && (
          <span id="error-message">
            There is some issue with entered data please check the format and
            the new line.
          </span>
        )}
        <div className="buttons-container">
          <div className="file">
            <label for="input-file">
              <CloudQueue />
              or upload a .txt file containing hosts/url
            </label>
            <input
              type="file"
              id="input-file"
              className="input-file"
              accept=".txt"
              onChange={(e) => handleFileChange(e.target.files[0])}
            />
          </div>
          <button
            style={{ height: "50px", width: "100px" }}
            onClick={() => clicked()}
            className="download-buttons"
          >
            TEST
          </button>
          <ToastContainer />
          {/* <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LdO31AlAAAAADOKmQDLYAoX1ilIAhcRIMqE-3BN"
            onChange={onChange}
          /> */}
        </div>
      </div>

      <Loader show={isLoading} />
      <div style={{ margin: "0 auto 100px auto", width: "70%" }}>
        <div className="results-container">
          {hasResultsArrived &&
            results?.map((z, index) => (
              <div style={{ margin: "20px auto" }}>
                {/* <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "95%", flexShrink: 0 }}>
                    <a href={'https://' + z.url}>{z.url}</a>{" "}
                    {z.headers && z.ipAddress
                      ? ": IP Address: " + z.ipAddress
                      : ""}
                    {z.headers && z.statusCode
                      ? " ; Status Code: " + z.statusCode
                      : ""}
                    {z.headers && z.title ? " ; Site Title: " + z.title : ""}
                    {z.headers && z.contentLength
                      ? " ; Content Length: " + z.contentLength
                      : ""}
                    {!z.headers && " :    Could not reach"}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <h3>The HTTP Headers are: </h3>
                    <div style={{ wordWrap: "break-word" }}>
                      {JSON.stringify(z.headers)}
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion> */}

                <div className="card page-card">
                  <div className="card-header text-truncate" title={z.url}>
                  <a href={!z.url.startsWith('http') ? "https://" + z.url : z.url}
                      target="_blank"
                    >
                      {z.url}
                    </a>
                  </div>
                  <div className="page-screenshot-container">
                    <img
                      src={z.screenshot}
                      className="card-img page-screenshot"
                      alt={z.url}
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">{z.title}</h5>
                    <p className="card-text">
                      <span
                        className={
                          "badge badge-pill text-break text-wrap " +
                          badgeClassForStatus(z.statusCode)
                        }
                      >
                        {z.statusCode}
                      </span>
                    </p>
                  </div>
                  <div className="card-footer">
                    <a
                      href="#"
                      className="btn btn-outline-primary btn-sm card-link" onClick={() => openModal(z.headers)}
                    >
                      View HTTP Headers
                    </a>
<div style={{display: showModal? 'block' : 'none' }} className="modal">

  <div className="modal-content">
    <span onClick={() => closeModal()} class="close">&times;</span>
    <p style={{'color': 'red', 'fontWeight': '800'}}>The HTTP Headers after probing are: </p>
    <p>{modalContent}</p>
  </div>

</div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );

  async function makeHTTPRequest(urls) {
    return await axios({
      method: "post",
      url: process.env.REACT_APP_API_URL,
      data: {
        targets: [urls],
        // captcha,
      },
      responseType: "application/json",
    });
  }
}

export default App;
