import React from "react";
import "./App.css";

function Headline() {
  return (
    <div className="App">
      <h2 style={{ color: "var(--primary-color)", 'lineHeight': '1.5', textAlign: "justify" }}>
      <span style={{ color: "var(--secondary-color)" }}>Probe for  HTTP headers </span> for bulk urls/hosts
        
        <br></br>
          Also get the <span style={{ color: "var(--secondary-color)" }}>visual attack surface </span> at your fingertips.
        
        <br></br>
        No need to wait for long..
        <br></br>
        See 1 by 1 result as soon as its scan is done.
      </h2>
    </div>
  );
}

export default Headline;
